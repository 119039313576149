import { Link, useI18next, withTranslation } from "gatsby-plugin-react-i18next"
import React from "react"
import styled from "styled-components"
import { respond } from "../_respond"
import ArrowUp from "../assets/next.svg"
import LarorikLogo from "../images/LarorikLogo-white.svg"
import OpinkirjoLogo from "../images/opinkirjo-logo-v2.svg"
import withSimpleErrorBoundary from "../util/withSimpleErrorBoundary"

const Wrapper = styled.div`
  border: 2.4px solid rgba(35, 31, 32, 0.8);
  display: grid;
  background: #252525;
  grid-template-columns: 1fr 0.5fr;
  padding: 4.5rem;
  color: #231f20;
  position: relative;

  ${respond.mobile`
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  margin: 0;
  padding : 2rem;
  `}

  div {
    width: 100%;

    span {
      display: block;
      font-family: "Lato";
      font-size: 16px;
      padding-right: 4rem;
      width: 80%;
      color: #f1f1f1;
      opacity: 0.7;

      ${respond.mobile`
      width: 100%;
      padding-right: 0;
      `}
    }

    img {
      margin-bottom: 1rem;
    }
  }

  div:last-of-type {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 8px;
    justify-content: end;
    font-family: "Josefin Sans", sans-serif;
  }

  svg {
    transition: fill 0.2s ease-in;

    :hover {
      fill: #fe9677;
    }
  }
`

const StyledLinkOutside = styled.a`
  &&& {
    text-decoration: none;
    color: #f1f1f1;
    font-size: 1.2rem;
    opacity: 0.6;
    transition: opacity 0.2s ease-in;
    width: 40%;

    ${respond.mobile`
  margin-bottom: .5rem;
  `}

    :hover {
      text-decoration: none;
      color: #f1f1f1;
      opacity: 1;
    }
  }
`

const StyledLink = styled(Link)`
  text-decoration: none;
  color: #f1f1f1;
  font-size: 1.2rem;
  opacity: 0.6;
  transition: opacity 0.2s ease-in;
  width: 40%;

  ${respond.mobile`
  margin-bottom: .5rem;
  `}

  :hover {
    text-decoration: none;
    color: #f1f1f1;
    opacity: 1;
  }
`

const StyledIcon = styled(ArrowUp)`
  position: absolute;
  top: 40%;
  right: 10%;
  height: 45px;
  width: 50px;
  padding: 1rem;
  background: #333;
  cursor: pointer;

  ${respond.mobile`
  top: 75%;
  `}
`

const Footer = (props) => {
  const { language } = useI18next()
  const scrollTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" })
  }

  return (
    <Wrapper>
      <div>
        {language === "sv" ? (
          <img src={LarorikLogo} width="150px" alt="Larorik logo" />
        ) : (
          <img src={OpinkirjoLogo} width="150px" alt="Opinkirjon tunnus" />
        )}
        <span>{props.t("footerIntroductionText")}</span>
      </div>
      <div>
        <StyledLink to="/faq">{props.t("linkTextToFAQ")}</StyledLink>
        <StyledLink to="/tekijat">{props.t("linkTextToCreators")}</StyledLink>
        <StyledLink to="/license">{props.t("linkTextToLicense")}</StyledLink>
        <StyledLinkOutside
          href={props.t("linkToDataProtection")}
          target="_blank"
          rel="noopener noreferrer"
        >
          {props.t("linkTextToDataProtection")}
        </StyledLinkOutside>
        {language === "fi" && (
          <StyledLink to="/accessibility-statement">
            {props.t("linkTextToAccessibilityStatement")}
          </StyledLink>
        )}
      </div>
      <StyledIcon onClick={scrollTop} />
    </Wrapper>
  )
}

export default withTranslation("common")(withSimpleErrorBoundary(Footer))
