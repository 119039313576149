import React, { useState } from "react"
import "./hamburgers.css"

const Hamburger = () => {
  const [isActive, setIsActive] = useState(false)

  const handleClick = () => {
    setIsActive(!isActive)
  }

  return (
    <div
      className={`hamburger hamburger--collapse ${isActive ? "is-active" : ""}`}
      onClick={handleClick}
    >
      <div className={`hamburger-box ${isActive ? "is-active" : ""}`}>
        <div className={`hamburger-inner ${isActive ? "is-active" : ""}`}></div>
      </div>
    </div>
  )
}

export default Hamburger
