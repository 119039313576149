import { faBullseye } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Link, withTranslation } from "gatsby-plugin-react-i18next"
import React, { useState } from "react"
import styled from "styled-components"
import withSimpleErrorBoundary from "../../util/withSimpleErrorBoundary"
import HamburgerTest from "../Hamburger/HamburgerTest"
import LanguageOptions from "../LanguageOptions"
import LoginControls from "../LoginControls"
import "./Navbar.css"

const StyledIcon = styled(FontAwesomeIcon)`
  font-size: 2rem;
  height: 2rem;
  color: #333;
`

const Navbar = (props) => {
  const [clicked, setClicked] = useState(false)
  const onClickHandler = () => {
    setClicked(!clicked)
  }

  return (
    <div className="Wrapper">
      <nav
        className="NavbarItems"
        style={{ background: props.navcolor ? props.navcolor : "#FE9677" }}
      >
        <div className="navbar-logo">
          <Link to="/" aria-label={props.t("toHomepage")} role="button">
            <StyledIcon icon={faBullseye} aria-hidden="true"></StyledIcon>
          </Link>
        </div>
        <ul className={clicked ? "nav-menu active" : "nav-menu"} role="list">
          <li className="container">
            <Link
              className="nav-links"
              to="/vinkkeja"
              aria-label={props.t("tipsToTeachers")}
              role="button"
              style={{ paddingTop: "15px" }}
            >
              {props.t("tipsToTeachers")}
            </Link>
            <ul className={clicked ? "tooltip" : "hide"}>
              <LoginControls />
              <Link
                className="nav-links"
                to="/faq"
                aria-label={props.t("aboutCourse")}
                role="button"
              >
                {props.t("aboutCourse")}
              </Link>
              <LanguageOptions />
            </ul>
          </li>
          <li style={{ margin: "16px" }}>
            <div
              className="menu-icon"
              onClick={onClickHandler}
              role="button"
              aria-label={props.t("openMenu")}
            >
              <HamburgerTest></HamburgerTest>
            </div>
          </li>
        </ul>{" "}
      </nav>{" "}
    </div>
  )
}

export default withTranslation("common")(withSimpleErrorBoundary(Navbar))
