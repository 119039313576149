import { graphql } from "gatsby"
import React, { Fragment } from "react"
import { Helmet } from "react-helmet"
import rehypeReact from "rehype-react"
import styled from "styled-components"
import Footer from "../components/Footer"
import Navbar from "../components/Navbar/Navbar"
import ScrollIndicator from "../components/ScrollIndicator"
import PagesContext from "../contexes/PagesContext"
import getNamedPartials from "../partials"
import { respond } from "../_respond"
import "./remark.css"

const ContentWrapper = styled.article`
  text-align: center;
  background: white;
  padding: 2rem 4rem;
  margin: 0;
  font-family: "Lato", sans-serif;

  ${respond.mobile`
  margin: 0;
  padding: 0;
`}

  ${respond.xs`

    margin: 0
  `}

  ${respond.sm`

    margin: 0
  `}

  ${respond.md`

  margin: 0
  `}

  ${respond.lg`

  margin: 0;
  `}



  ${respond.xs`
  padding: 0;
`}

  ${respond.sm`
  padding: 0;
`}
`

export default function HomePageTemplate(props) {
  const { data } = props
  const { frontmatter, htmlAst } = data.page
  const allPages = data.allPages.edges.map((o) => o.node?.frontmatter)
  const partials = getNamedPartials()
  const language = data.locales.edges[0].node.language
  const renderAst = new rehypeReact({
    createElement: React.createElement,
    components: partials,
  }).Compiler

  const filePath = data.page.fileAbsolutePath.substring(
    data.page.fileAbsolutePath.lastIndexOf("/data/"),
    data.page.fileAbsolutePath.length,
  )

  return (
    <>
      <Fragment>
        <Helmet title={frontmatter.title} />{" "}
        <PagesContext.Provider
          value={{
            current: {
              frontmatter: frontmatter,
              filePath: filePath,
              allPages: allPages,
              language,
            },
          }}
        >
          <Fragment>
            <Navbar />
            <ScrollIndicator />
            <ContentWrapper> {renderAst(htmlAst)} </ContentWrapper>
            <Footer />
          </Fragment>
        </PagesContext.Provider>
      </Fragment>
    </>
  )
}

export const pageQuery = graphql`
  query ($path: String!, $language: String!) {
    page: markdownRemark(frontmatter: { path: { eq: $path } }) {
      htmlAst
      html
      frontmatter {
        path
        title
        banner
      }
      fileAbsolutePath
    }
    allPages: allMarkdownRemark {
      edges {
        node {
          id
          frontmatter {
            path
            title
          }
        }
      }
    }
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`
