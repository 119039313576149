import React, { useContext } from "react"
import { nthIndex } from "../util/strings"
import { useI18next, withTranslation } from "gatsby-plugin-react-i18next"
import withSimpleErrorBoundary from "../util/withSimpleErrorBoundary"
import { Button } from "@material-ui/core"
import FinnishFlag from "../assets/fi_flag.svg"
import SwedishFlag from "../assets/sv_flag.svg"
import PagesContext from "../contexes/PagesContext"
import styled from "styled-components"

const StyledWrapper = styled.div`
  padding-bottom: 4px;
`

const LanguageOptions = ({ t }) => {
  const { language, originalPath, changeLanguage } = useI18next()
  const pagesContext = useContext(PagesContext)

  let currentPath
  let currentChapter
  let currentPart
  let partPath
  if (pagesContext !== undefined) {
    currentPath = pagesContext.current.frontmatter.path

    if (language === "fi") {
      currentChapter = currentPath.substr(1, 7)
    } else {
      currentChapter = currentPath.substr(4, 7)
    }

    if (language === "fi") {
      currentPart = nthIndex(currentPath, "/", 2) + 1
    } else {
      currentPart = nthIndex(currentPath, "/", 3) + 1
    }
    if (currentPart !== -1) {
      partPath = currentPath.substr(currentPart, 1)
    }
  }
  const onClick = (e) => {
    if (e === language) {
      return
    }
    if (pagesContext === undefined) {
      changeLanguage(e, originalPath)
      return
    }
    if (
      language === "fi" &&
      (currentPath.length < 8 || currentPath.substr(1, 4) !== "part")
    ) {
      changeLanguage(e, originalPath)
      return
    } else if (
      language !== "fi" &&
      (currentPath.length < 11 || currentPath.substr(4, 4) !== "part")
    ) {
      changeLanguage(e, originalPath)
      return
    }
    const pathRegexFI = new RegExp("^/part-[1-9]$", "gm")
    const pathRegexOtherLanguage = new RegExp("^/[a-z]{2}/part-[1-9]$", "gm")
    if (e === "fi" && currentPath.match(pathRegexOtherLanguage)) {
      changeLanguage(e, `/part-${currentPath.substr(9, 1)}`)
      return
    } else if (currentPath.match(pathRegexFI)) {
      changeLanguage(e, `/part-${currentPath.substr(6, 1)}`)
      return
    }

    const regex = new RegExp(`${currentChapter}${partPath}`, "gm")
    let chapters = pagesContext.all.filter((o) => o.path.match(regex))
    let newLanguagePath
    if (e === "fi") {
      const newPathRegex = new RegExp("^/part-[1-9]", "gm")
      newLanguagePath = chapters.find((part) =>
        part.path.match(newPathRegex),
      ).path
    } else {
      newLanguagePath = chapters.find((part) => part.path.substr(1, 2) === e)
      newLanguagePath = newLanguagePath.path.substr(3)
    }
    changeLanguage(e, newLanguagePath)
  }
  return (
    <StyledWrapper>
      <Button
        onClick={() => onClick("fi")}
        color="secondary"
        aria-label={`${t("changeLanguageToFI")}`}
        style={{ marginRight: "0em" }}
      >
        <FinnishFlag style={{ maxWidth: "30px", maxHeight: "20px" }} />
      </Button>
      <Button
        onClick={() => onClick("sv")}
        aria-label={`${t("changeLanguageToSV")}`}
      >
        <SwedishFlag style={{ width: "30px", height: "20px" }} />
      </Button>
    </StyledWrapper>
  )
}

export default withTranslation("common")(
  withSimpleErrorBoundary(LanguageOptions),
)
